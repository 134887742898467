var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-spin',{attrs:{"spinning":_vm.spinning}},[_c('a-form',{attrs:{"form":_vm.form}},[_c('a-row',{attrs:{"gutter":24}},[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"姓名","has-feedback":""}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'realName',
              {
                rules: [
                  {
                    required: true,
                    message: _vm.l('validation.required'),
                  } ],
              } ]),expression:"[\n              'realName',\n              {\n                rules: [\n                  {\n                    required: true,\n                    message: l('validation.required'),\n                  },\n                ],\n              },\n            ]"}],staticStyle:{"width":"100%"},attrs:{"disabled":true,"placeholder":"请输入姓名"}})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"手机号码","has-feedback":""}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'phoneNumber',
              {
                rules: [
                  {
                    required: true,
                    message: _vm.l('validation.required'),
                  } ],
              } ]),expression:"[\n              'phoneNumber',\n              {\n                rules: [\n                  {\n                    required: true,\n                    message: l('validation.required'),\n                  },\n                ],\n              },\n            ]"}],staticStyle:{"width":"100%"},attrs:{"disabled":true,"placeholder":"请输入手机号码"}})],1)],1)],1),_c('a-row',{attrs:{"gutter":24}},[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"身份证号码","has-feedback":""}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'idCard',
              {
                rules: [
                  {
                    required: true,
                    message: _vm.l('validation.required'),
                  } ],
              } ]),expression:"[\n              'idCard',\n              {\n                rules: [\n                  {\n                    required: true,\n                    message: l('validation.required'),\n                  },\n                ],\n              },\n            ]"}],attrs:{"disabled":true,"placeholder":"请输入身份证号码"}})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"实名认证类型","has-feedback":""}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'verifiedWay',
              {
                rules: [
                  {
                    required: true,
                    message: _vm.l('ThisFieldIsRequired'),
                  } ],
                initialValue: undefined,
              } ]),expression:"[\n              'verifiedWay',\n              {\n                rules: [\n                  {\n                    required: true,\n                    message: l('ThisFieldIsRequired'),\n                  },\n                ],\n                initialValue: undefined,\n              },\n            ]"}],attrs:{"disabled":true,"placeholder":"请选择"}},_vm._l((_vm.defaultSelectList),function(it,i){return _c('a-select-option',{key:i,attrs:{"value":it.value}},[_vm._v(" "+_vm._s(it.label)+" ")])}),1)],1)],1)],1),(_vm.isGranted("Pages.LGModul.PersonnelRoster.BasicIdCard"))?_c('a-row',{attrs:{"gutter":24}},[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"身份证正面照片"}},[(_vm.idCardPhotoFront_fileList.length > 0)?_c('a-upload',{attrs:{"action":_vm.uploadUrl,"multiple":true,"data":{ subdir: this.subdirName },"file-list":_vm.idCardPhotoFront_fileList,"showUploadList":{
              showPreviewIcon: _vm.isShowIdCardPhotoFront > 0 ? true : false,
              showRemoveIcon: false,
            },"list-type":"picture-card"}}):_vm._e()],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"身份证反面照片"}},[(_vm.idCardPhotoVerso_fileList.length > 0)?_c('a-upload',{attrs:{"action":_vm.uploadUrl,"multiple":true,"data":{ subdir: this.subdirName },"file-list":_vm.idCardPhotoVerso_fileList,"showUploadList":{
              showPreviewIcon: _vm.isShowIdCardPhotoVerso ? true : false,
              showRemoveIcon: false,
            },"list-type":"picture-card"}}):_vm._e()],1)],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }