<template>
  <a-spin :spinning="spinning">
    <ele-table
      ref="rt"
      :columns="columns"
      :table-data="tableData"
      :total-items="totalItems"
      :is-full="false"
      :actionsType="actionsType"
      :current-page="pageNumber"
      :hide-row-selection="false"
      @emitRefreshData="clearFilterAndRefresh"
      @emitSelectChange="updateSelectChange"
      @emitOnPageChange="updatePageChange"
      @emitShowSizeChange="updateShowSizeChange"
    >
      <a slot="totalCount" slot-scope="text">{{ text }}</a>
      <!-- 按钮 -->
      <a-row :gutter="24" class="btn--container">
        <a-col class="gutter-row" :span="8">
          <a-input
            v-model="SearchDto.filterText"
            :placeholder="l('模板名称/合同编号')"
          />
        </a-col>
        <a-col class="gutter-row" :span="8">
          <a-button type="primary" @click="search">
            {{ l("Search") }}
          </a-button>
          <a-button @click="refreshGoFirstPage"> {{ l("Reset") }}</a-button>
        </a-col>
      </a-row>
    </ele-table>
  </a-spin>
</template>

<script>
import { AppComponentBase } from "@/shared/component-base";
import EmailNotifications from "@/components/react-emails";
import { CommonServiceProxy } from "@/shared/common-service";
import { AppConsts } from "@/abpPro/AppConsts";
import { environment } from "@/environments/environment";
import EleTable from "@/components/ele-table";
import { ModalHelper } from "@/shared/helpers";
import moment from "moment";

export default {
  name: "personnel-agreement-list",
  mixins: [AppComponentBase],
  props: ["personnelId"],
  components: { EleTable },
  data() {
    return {
      spinning: false,
      SearchDto: {
        SkipCount: 0,
        MaxResultCount: 50,
        Sorting: "",
        filterText: "",
      },
      commonService: null,
      tableData: [],
      // 总数
      totalItems: 0,
      // 当前页码
      pageNumber: 1,
      // 共多少页
      totalPages: 1,
      // 条数显示范围
      pagerange: [1, 1],
      // 显示条数
      pageSizeOptions: ["10", "20", "30", "40"],
      actionsType: {},
      signStatuList: ["未签署", "单方签署", "已经签署", "归档", "已通知"],
      // 用户表格
      columns: [
        {
          title: this.l("模板名称"),
          dataIndex: "agreementTemplateName",
          sorter: false,
          align: "center",
          fixed: "left",
          scopedSlots: { customRender: "agreementTemplateName" },
        },
        {
          title: this.l("签署状态"),
          dataIndex: "signStatusStr",
          sorter: false,
          align: "center",
          fixed: "left",
          scopedSlots: { customRender: "signStatusStr" },
        },
        {
          title: this.l("状态"),
          dataIndex: "isInvalid",
          // sorter: true,
          align: "center",
          type: "tag",
          width: 100,
          scopedSlots: { customRender: "isInvalid" },
          customRender: (text) => {
            return text ? "已作废" : "正确";
            // return text ? (
            //   <a-tag color="#f50">已作废</a-tag>
            // ) : (
            //   <a-tag color="#87d068">正常</a-tag>
            // );
          },
        },
        {
          title: this.l("合同编号"),
          dataIndex: "contractNo",
          sorter: false,
          align: "center",
          scopedSlots: { customRender: "contractNo" },
        },
        {
          title: this.l("合同流水号"),
          dataIndex: "transactionNo",
          sorter: false,
          align: "center",
          scopedSlots: { customRender: "transactionNo" },
        },
        {
          title: this.l("签署时间"),
          dataIndex: "signTimeStr",
          sorter: false,
          align: "center",
          scopedSlots: { customRender: "signTimeStr" },
        },
        {
          title: this.l("项目名称"),
          dataIndex: "projectName",
          sorter: false,
          align: "center",
          scopedSlots: { customRender: "projectName" },
        },
        {
          title: this.l("项目编码"),
          dataIndex: "projectCode",
          sorter: false,
          align: "center",
          scopedSlots: { customRender: "projectCode" },
        },
        {
          title: this.l("所属客户"),
          dataIndex: "customerName",
          sorter: false,
          align: "center",
          scopedSlots: { customRender: "customerName" },
        },
        {
          title: this.l("签约公司"),
          dataIndex: "signCompanyName",
          sorter: false,
          align: "center",
          scopedSlots: { customRender: "signCompanyName" },
        },
        {
          title: this.l("创建人"),
          dataIndex: "creatorUserName",
          sorter: false,
          align: "center",
          scopedSlots: { customRender: "creatorUserName" },
        },
        {
          title: this.l("创建时间"),
          dataIndex: "creationTimeStr",
          sorter: false,
          align: "center",
          scopedSlots: { customRender: "creationTimeStr" },
        },
        {
          title: this.l("Actions"),
          dataIndex: "actions",
          fixed: "right",
          sorter: false,
          align: "center",
          scopedSlots: { customRender: "actions" },
        },
      ],
      selectedRowKeys: [],
      selectedRows: [],
    };
  },
  created() {
    this.commonService = new CommonServiceProxy(this.$apiUrl, this.$api);
    this.initActionsType();
    if (this.personnelId) {
      this.getData();
    }
  },
  mounted() {},
  methods: {
    getData() {
      this._getProjectPersonnelAgreement();
    },
    async _getProjectPersonnelAgreement() {
      this.spinning = true;
      this.$nextTick(() => {
        this.$refs.rt.clearData();
      });
      try {
        let res = await this.commonService.post({
          url: "/api/services/app/Personnel/GetProjectAgreementByPersonnelIdPaged",
          params: {
            personnelId: this.personnelId,
            contractNo: this.SearchDto.filterText,
            sorting: this.SearchDto.Sorting,
            maxResultCount: this.SearchDto.MaxResultCount,
            skipCount: this.SearchDto.SkipCount,
          },
        });
        this.tableData = res.items.map((item) => {
          return {
            ...item,
            signTimeStr: item.signTime
              ? moment(item.signTime).format("YYYY-MM-DD HH:mm:ss")
              : "-",
            creationTimeStr: item.creationTime
              ? moment(item.creationTime).format("YYYY-MM-DD HH:mm:ss")
              : "-",
            signStatusStr: this.signStatuList[item.signStatus],
            isInvalid: item.isInvalid ? "已作废" : "正常",
            isInvalidColor: item.isInvalid ? "#f50" : "#87d068",
          };
        });
        this.totalItems = res.totalCount;
        this.totalPages = Math.ceil(2 / this.SearchDto.MaxResultCount);
        this.pagerange = [
          (this.pageNumber - 1) * this.SearchDto.MaxResultCount + 1,
          this.pageNumber * this.SearchDto.MaxResultCount,
        ];
        this.spinning = false;
      } catch (error) {
        this.spinning = false;
      }
    },
    initActionsType() {
      let _this = this;
      let obj = {
        type: "templete-list",
        isShow: true,
        fns: {
          detailList: [],
          dropList: [
            {
              granted: true,
              name: this.l("查看协议"),
              icon: "eye",
              fn: (data) => {
                console.log(data);
                if (data.viewUrl) {
                  window.open(data.viewUrl);
                } else {
                  _this.$message.info("暂无协议");
                }
              },
            },
            {
              granted: true,
              name: this.l("下载协议"),
              icon: "download",
              fn: (data) => {
                if (data.downloadUrl) {
                  window.location.href = data.downloadUrl;
                } else {
                  _this.$message.info("暂无协议");
                }
              },
            },
          ],
        },
      };
      this.actionsType = obj;
    },
    search() {
      this.pageNumber = 1;
      this.SearchDto.SkipCount = 0;
      this.getData();
    },
    // 排序更新data
    updateSortData(newV) {
      let { columnKey, order } = newV;
      this.SearchDto.Sorting = order ? `${columnKey} ${order}` : "";
      this.getData();
    },
    // 分页
    updatePageChange(newV) {
      let { page, pageSize } = newV;
      this.onChange(page, pageSize);
    },
    updateShowSizeChange(newV) {
      let { current, size } = newV;
      this.showSizeChange(current, size);
    },
    // table选择事件
    updateSelectChange(newV) {
      let { selectedRowKeys, selectedRows } = newV;
      this.onSelectChange(selectedRowKeys, selectedRows);
    },
    onChange(page, pageSize) {
      this.pageNumber = page;
      this.SearchDto.SkipCount = (page - 1) * this.SearchDto.MaxResultCount;
      this.getData();
    },
    showSizeChange(current, size) {
      this.pageNumber = 1;
      this.SearchDto.MaxResultCount = size;
      this.getData();
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRows = selectedRows;
    },
    // 清除条件并刷新
    clearFilterAndRefresh() {
      this.refreshGoFirstPage();
    },
    refreshGoFirstPage() {
      this.SearchDto.filterText = "";
      this.pageNumber = 1;
      this.SearchDto.SkipCount = 0;
      this.getData();
    },
    // 分页事件
    showTotalFun() {
      return this.l(
        "GridFooterDisplayText",
        this.pageNumber,
        this.totalPages,
        this.totalItems,
        this.pagerange[0],
        this.pagerange[1]
      );
    },
  },
};
</script>

<style scoped lang="less">
// @import "../../../../styles/common.less";

.modal-header {
  .anticon-share-alt {
    margin-right: 10px;
  }
}
.form-wrapper {
  .ant-form-item {
    margin-bottom: 14px;
  }
}
</style>
