import { render, staticRenderFns } from "./personnel-basic-list.vue?vue&type=template&id=04051f51&scoped=true&"
import script from "./personnel-basic-list.vue?vue&type=script&lang=js&"
export * from "./personnel-basic-list.vue?vue&type=script&lang=js&"
import style0 from "./personnel-basic-list.vue?vue&type=style&index=0&id=04051f51&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "04051f51",
  null
  
)

export default component.exports