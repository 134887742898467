<template>
  <a-spin :spinning="spinning">
    <!-- <react-table
      :table-data="tableData"
      :total-items="totalItems"
      :showTotal="showTotalFun"
      :columns="columns"
      :scroll="{ x: 1500 }"
      :hideRowSelection="false"
      :customerSelectedRowKeys="selectedRowKeys"
      :current-page="pageNumber"
      :is-full="true"
      size="small"
      @emitRefreshData="clearFilterAndRefresh"
      @emitSelectChange="updateSelectChange"
      @emitOnPageChange="updatePageChange"
      @emitShowSizeChange="updateShowSizeChange"
      @emitSortData="updateSortData"
    > -->
    <ele-table
      ref="rt"
      :columns="columns"
      :table-data="tableData"
      :total-items="totalItems"
      :is-full="false"
      :current-page="pageNumber"
      :hide-row-selection="false"
      @emitRefreshData="clearFilterAndRefresh"
      @emitSelectChange="updateSelectChange"
      @emitOnPageChange="updatePageChange"
      @emitShowSizeChange="updateShowSizeChange"
    >
      <a-row :gutter="4">
        <a-col class="gutter-row" :span="4">
          <a-input
            v-model.trim="SearchDto.filterText"
            placeholder="发放批次"
            style="width: 98%"
            allowClear
          ></a-input>
        </a-col>
        <a-col class="gutter-row" :span="3">
          <a-month-picker
            v-model="SearchDto.yearMonth"
            :placeholder="l('SalaryMonth')"
            valueFormat="YYYY-MM"
            style="width: 98%"
          />
        </a-col>
        <a-col class="gutter-row" :span="3">
          <a-select
            :disabled="disableStatus"
            v-model="SearchDto.PayStatus"
            placeholder="发放状态"
            style="width: 98%"
            allowClear
          >
            <a-select-option
              v-for="item in SalaryStatus"
              :key="item.value"
              :value="item.value"
            >
              {{ item.label }}
            </a-select-option>
          </a-select>
        </a-col>
        <a-col class="gutter-row" :span="5">
          <a-select
            v-model="SearchDto.CustomerId"
            style="width: 98%"
            show-search
            :filter-option="filterOption"
            :placeholder="l('PleaseSelectCustomer')"
            allowClear
          >
            <a-select-option
              v-for="item in Customers"
              :key="item.value"
              :value="item.value"
            >
              {{ item.label }}
            </a-select-option>
          </a-select>
        </a-col>
        <!-- <a-col class="gutter-row" :span="3">
          <a-select
            v-model="SearchDto.PayCompanyId"
            style="width: 98%"
            show-search
            :filter-option="filterOption"
            placeholder="请选择发放公司"
            allowClear
          >
            <a-select-option
              v-for="item in PayCompanys"
              :key="item.value"
              :value="item.value"
            >
              {{ item.label }}
            </a-select-option>
          </a-select>
        </a-col> -->
        <a-col class="gutter-row" :span="5">
          <a-button-group>
            <a-button type="primary" @click="searchData">
              {{ l("Search") }}
            </a-button>
            <a-button @click="clearAndGetData"> {{ l("Reset") }}</a-button>
            <a-button @click="showSearchDrawer">高级搜索</a-button>
          </a-button-group>
        </a-col>

        <!-- <a-col class="gutter-row" style="text-align: right" :span="4">
          <a-button-group>
            <a-button
              type="primary"
              :disabled="selectedRowKeys.length == 0"
              v-if="isGranted('Pages.LGModule.Salary.BatchReSend')"
              @click="showBatchReSalary()"
            >
              <a-icon type="reload" />
              <span>重新发放</span>
            </a-button>
            <a-button
              v-if="isGranted('Pages.SalaryDetailsPermissions.Export')"
              @click="exportToExcel()"
            >
              <a-icon type="file-excel" />
              <span>{{ l("Export") }}</span>
            </a-button>
          </a-button-group>
        </a-col> -->
      </a-row>
    </ele-table>
    <a-drawer
      title="搜索"
      :width="750"
      :visible="searchDrawerVisible"
      :body-style="{ paddingBottom: '80px' }"
      @close="onSearchDrawerClose"
    >
      <a-form :form="SearchDto" layout="vertical" hide-required-mark>
        <a-row :gutter="16">
          <a-col :span="12">
            <a-form-item label="关键字">
              <a-input
                v-model.trim="SearchDto.filterText"
                placeholder="发放批次"
              ></a-input>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="发放月份">
              <a-month-picker
                v-model="SearchDto.yearMonth"
                :placeholder="l('SalaryMonth')"
                valueFormat="YYYY-MM"
              />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="16">
          <a-col :span="12">
            <a-form-item label="发放时间">
              <a-range-picker
                :show-time="{ format: 'HH:mm' }"
                format="YYYY-MM-DD HH:mm"
                :placeholder="['发放开始时间', '发放结束时间']"
                v-model="startToEndDate"
                @change="payTimeOnChange"
              />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="发放状态">
              <a-select
                :disabled="disableStatus"
                v-model="SearchDto.PayStatus"
                placeholder="发放状态"
                allowClear
              >
                <a-select-option
                  v-for="item in SalaryStatus"
                  :key="item.value"
                  :value="item.value"
                >
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="16">
          <a-col :span="12">
            <a-form-item label="客户">
              <a-select
                v-model="SearchDto.CustomerId"
                show-search
                :filter-option="filterOption"
                :placeholder="l('PleaseSelectCustomer')"
                allowClear
              >
                <a-select-option
                  v-for="item in Customers"
                  :key="item.value"
                  :value="item.value"
                >
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="发放公司">
              <a-select
                v-model="SearchDto.PayCompanyId"
                show-search
                :filter-option="filterOption"
                placeholder="请选择发放公司"
                allowClear
              >
                <a-select-option
                  v-for="item in PayCompanys"
                  :key="item.value"
                  :value="item.value"
                >
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="16">
          <a-col class="gutter-row" :span="12">
            <a-form-item label="项目">
              <a-input
                v-model="SearchDto.ProjectNames"
                placeholder="项目名称"
                @click="selectProjectModal"
              >
              </a-input>
            </a-form-item>
          </a-col>
        </a-row>
        <div>
          <a-button :style="{ marginRight: '8px' }" @click="clearAndGetData">
            <a-icon type="reload" />
            <span>重置</span>
          </a-button>
          <a-button type="primary" @click="searchData">
            <a-icon type="search" />
            <span>搜索</span>
          </a-button>
        </div>
      </a-form>
    </a-drawer>
    <a-modal
      title="重新发放"
      :confirm-loading="spinning"
      okText="重新发放"
      cancelText="取消"
      v-model="showModalBatchReSalary"
      @ok="BatchReSalary"
      @cancel="showModalBatchReSalary = false"
    >
      <p>
        选择数量：<span style="color: red">{{ selectedRowKeys.length }}条</span
        >， 总金额：<span style="color: red">{{ showTotalAmount }}元</span>
      </p>
      <p><span style="color: red">*</span> 备注：</p>
      <p><a-textarea v-model="remark" placeholder="请输入备注" :rows="4" /></p>

      <a-table
        v-if="errorData.length > 0"
        :columns="errorColumns"
        :row-key="(record) => record.personnelId"
        :data-source="errorData"
      >
      </a-table>
    </a-modal>
  </a-spin>
</template>
<script>
import { AppComponentBase } from "@/shared/component-base";
import { ModalHelper } from "@/shared/helpers";
import moment from "moment";
import EleTable from "@/components/ele-table";
import SalaryDetail from "@/components/salary/salarydetail.vue";
import { CommonServiceProxy } from "@/shared/common-service";
import { fileDownloadService } from "@/shared/utils";
import SelectPageList from "@/components/linggong/select-page-list";

export default {
  name: "personnel-salarypay-list",
  mixins: [AppComponentBase],
  //   props: {
  //     defaultStatus: {
  //       type: Number,
  //     },
  //   },
  props: ["personnelId"],
  components: { EleTable },
  data: function () {
    return {
      showModalBatchReSalary: false,
      remark: "",
      spinning: false,
      searchDrawerVisible: false,
      SearchDto: {
        filterText: "",
        yearMonth: "",
        startPayTime: null,
        endPayTime: null,
        PayStatus: undefined,
        CustomerId: undefined,
        PayCompanyId: undefined,
        sorting: "",
        skipCount: 0,
        maxResultCount: 50,
        ProjectName: "",
        ProjectIds: "",
      },
      commonService: null,
      totalItems: 0,
      pageNumber: 1,
      pagerange: [1, 1],
      totalPages: 1,
      selectedRowKeys: [],
      selectedRows: [],
      tableData: [],
      showTotalAmount: 0,
      disableStatus: false,
      errorData: [],
      errorColumns: [
        {
          title: "姓名",
          dataIndex: "realName",
        },
        {
          title: "手机号",
          dataIndex: "phoneNumber",
        },
        {
          title: "身份证号码",
          dataIndex: "idCard",
        },
        {
          title: "错误原因",
          dataIndex: "remarks",
        },
      ],
      SalaryStatus: [
        // { label: this.l("PleaseSelectStatus"), value: -1 },
        { label: "待处理", value: 0 },
        { label: "发放成功", value: 1 },
        { label: "发放失败", value: 2 },
        { label: "已重新发放", value: 3 },
        { label: "已驳回", value: 4 },
        { label: "发放中", value: 5 },
        { label: "已作废", value: 6 },
      ],
      columns: [],
      PayCompanys: [], // { value: "", label: this.l("PlaceSelectPayCompany") }
      Customers: [], // { value: "", label: this.l("PleaseSelectCustomer") }
        startToEndDate: [],
    };
  },
  methods: {
    showSearchDrawer() {
      this.searchDrawerVisible = true;
    },
    onSearchDrawerClose() {
      this.searchDrawerVisible = false;
    },
    payTimeOnChange(_, dateString) {
      if (dateString[0] === "") {
        this.SearchDto.startPayTime = null;
        this.SearchDto.endPayTime = null;
      } else {
        this.SearchDto.startPayTime = dateString[0];
        this.SearchDto.endPayTime = dateString[1];
      }
    },
    initColumns() {
      var vm = this;
      this.columns = [
        {
          title: "主批次号",
          dataIndex: "batchNo",
          align: "center",
          width: 260,
          ellipsis: true,
        },
        {
          title: "子批次号",
          dataIndex: "number",
          align: "center",
          width: 200,
          ellipsis: true,
        },
        {
          title: "收款人",
          dataIndex: "userName",
          align: "center",
          // fixed: "left",
          scopedSlots: { customRender: "userName" },
        },
        {
          title: "银行账号",
          dataIndex: "bankNum",
          align: "center",
          width: 220,
          ellipsis: true,
        },
        {
          title: "银行名称",
          dataIndex: "bankName",
          align: "center",
          width: 200,
          ellipsis: true,
        },
        {
          title: "发放金额",
          dataIndex: "amount",
          align: "center",
          ellipsis: true,
          customRender: (text) => {
            return `￥${text}`;
          },
        },
        {
          title: "身份证号码",
          dataIndex: "idCard",
          align: "center",
          width: 200,
          ellipsis: true,
        },
        {
          title: "手机号码",
          dataIndex: "phone",
          align: "center",
          width: 200,
          ellipsis: true,
        },
        {
          title: "签约公司",
          dataIndex: "payCompanyName",
          align: "center",
          // fixed: "left",
          ellipsis: true,
        },
        {
          title: "客户名称",
          dataIndex: "customerName",
          align: "center",
          width: 220,
          ellipsis: true,
        },
        {
          title: "是否提现",
          dataIndex: "withdraw",
          align: "center",
          ellipsis: true,
        },
        {
          title: this.l("发放时间"),
          dataIndex: "payTime",
          align: "center",
          sorter: true,
          ellipsis: true,
          // customRender: (text) => {
          //   if (text) {
          //     let payTimeStr = text.replaceAll("T", " ").split(".")[0];
          //     return payTimeStr?payTimeStr:"-";
          //   } else {
          //     return "-";
          //   }
          // },
        },
        {
          title: "项目名称",
          dataIndex: "projectName",
          align: "center",
          width: 220,
          ellipsis: true,
        },
        {
          title: this.l("Status"),
          dataIndex: "statusName",
          //   fixed: "right",
          align: "center",
          // customRender: (text) => {
          //   return <a-tag color="#87d068">{{ text }}</a-tag>;
          // },
        },
        {
          title: "错误原因",
          dataIndex: "payResult",
          align: "center",
          ellipsis: true,
        },
        {
          title: "信息状态",
          dataIndex: "identicalBankStr",
          align: "center",
          // width: 240,
          type: "tag",
          ellipsis: true,
          customRender: (text) => {
            if (text != "") {
              return <a-tag color="#f50">已更改信息</a-tag>;
            }
            return "";
          },
        },
      ];
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    searchData() {
      this.searchDrawerVisible = false;
      this.pageNumber = 1;
      this.SearchDto.skipCount = 0;
      this.getData();
    },
    getAllPayCompany() {
      this.spinning = true;
      this.$api
        .request({
          url: this.$apiUrl + "/api/services/app/Organization/GetSelectList",
          method: "GET",
        })
        .then((res) => {
          // this.PayCompanys.splice(0, this.PayCompanys.length);
          if (res && res.data) {
            this.PayCompanys = res.data.map((item) => {
              return {
                value: item.value,
                label: item.text,
              };
            });
          }
        })
        .catch((e) => {
          abp.message.error(this.l("Error500Desc"));
          console.error(e);
        })
        .finally(() => {
          this.spinning = false;
        });
    },
    getAllCutomer() {
      let options = {
        url: this.$apiUrl + "/api/services/app/Customer/GetCustomerOption",
        method: "GET",
      };
      this.$api
        .request(options)
        .then((res) => {
          if (res.status == 200) {
            this.Customers.splice(1, this.Customers.length - 1);
            if (res.data) {
              for (let i = 0; i < res.data.length; i++) {
                this.Customers.push(res.data[i]);
              }
            }
          }
        })
        .catch((e) => {
          console.error(e);
        });
    },
    showBatchReSalary() {
      if (this.selectedRowKeys.length == 0) {
        abp.message.error("请先选择需要批量重新发放的数据！");
        return;
      }
      console.log(this.selectedRows);
      let amountAll = 0;
      this.selectedRows.forEach((value, index) => {
        amountAll += value.amount;
      });
      this.showTotalAmount = amountAll.toFixed(2);
      this.remark = "";
      this.errorData = [];
      this.showModalBatchReSalary = true;
    },
    /**
     * 批量重发
     */
    async BatchReSalary() {
      if (this.remark == "") {
        abp.message.error("备注不可为空");
        return;
      }
      let selectIds = this.selectedRows.map((value, index) => {
        return value.id;
      });
      this.spinning = true;
      try {
        let res = await this.commonService.post({
          url: "/api/services/app/Salary/BatchReGenerateSalary",
          params: {
            remark: this.remark,
            salaryPayRecordIds: selectIds,
          },
        });
        if (res.length > 0) {
          this.errorData = res;
          abp.message.error("发放失败，请查看错误原因！");
        } else {
          abp.message.success(this.l("SuccessfullyOperation"));
          this.showModalBatchReSalary = false;
          this.getData();
        }

        console.log(res);
        //fileDownloadService.downloadTempFile(res);
        this.spinning = false;
      } catch (error) {
        console.log(error);
        this.spinning = false;
      }
    },

    /**
     * 导出为excel
     */
    async exportToExcel() {
      this.spinning = true;
      try {
        let res = await this.commonService.get({
          url: "/api/services/app/Salary/GetToExcelFileByDetails",
          params: {
            ...this.SearchDto,
            payStatus:
              this.SearchDto.PayStatus === undefined
                ? null
                : this.SearchDto.PayStatus,
            customerId:
              this.SearchDto.CustomerId === undefined
                ? null
                : this.SearchDto.CustomerId,
            companyId:
              this.SearchDto.PayCompanyId === undefined
                ? null
                : this.SearchDto.PayCompanyId,
            sorting:
              this.SearchDto.sorting == "" ? null : this.SearchDto.sorting,
          },
        });
        console.log(res);
        fileDownloadService.downloadTempFile(res);
        this.spinning = false;
      } catch (error) {
        console.log(error);
        this.spinning = false;
      }
    },

    getData() {
      if (this.SearchDto.ProjectId == "") {
        return false;
      }
      this.selectedRowKeys = [];
      this.selectedRows = [];
      this.spinning = true;
      this.$nextTick(() => {
        this.$refs.rt.clearData();
      });
      let month = this.SearchDto.yearMonth;
      console.info(month);
      console.log(this.SearchDto.startPayTime, this.SearchDto.endPayTime);
      let options = {
        url:
          this.$apiUrl +
          "/api/services/app/Personnel/GetSalaryDetailByPersonnelIdPaged",
        method: "GET",
        params: {
          ...this.SearchDto,
          payStatus:
            this.SearchDto.PayStatus === undefined
              ? null
              : this.SearchDto.PayStatus,
          customerId:
            this.SearchDto.CustomerId === undefined
              ? null
              : this.SearchDto.CustomerId,
          companyId:
            this.SearchDto.PayCompanyId === undefined
              ? null
              : this.SearchDto.PayCompanyId,
          sorting: this.SearchDto.sorting == "" ? null : this.SearchDto.sorting,
          personnelId: this.personnelId,
        },
      };
      this.$api
        .request(options)
        .then((res) => {
          if (res.status == 200) {
            let data = res.data;
            this.totalPages = Math.ceil(
              data.totalCount / this.SearchDto.maxResultCount
            );
            this.totalItems = data.totalCount;
            this.pagerange = [
              (this.pageNumber - 1) * this.SearchDto.maxResultCount + 1,
              this.pageNumber * this.SearchDto.maxResultCount,
            ];
            this.tableData = data.items.map((item) => {
              return {
                ...item,
                payTime:item.payTime? moment(item.payTime).format("YYYY-MM-DD"):"-",
                identicalBankStrColor: item.identicalBankStr ? "#87d068" : "",
              };
            });
            console.log(this.tableData);
          }
        })
        .catch((e) => {
          abp.message.error(this.l("Error500Desc"));
          console.error(e);
        })
        .finally(() => {
          this.spinning = false;
        });
    },
    clearFilterAndRefresh() {
      this.selectedRowKeys = [];
      this.selectedRows = [];
      this.SearchDto.skipCount = 0;
      this.getData();
    },
    showTotalFun() {
      return this.l(
        "GridFooterDisplayText",
        this.pageNumber,
        this.totalPages,
        this.totalItems,
        this.pagerange[0],
        this.pagerange[1]
      );
    },
    updateShowSizeChange(newV) {
      this.selectedRowKeys = [];
      this.selectedRows = [];
      let { current, size } = newV;
      this.showSizeChange(current, size);
    },
    showSizeChange(current, size) {
      this.selectedRowKeys = [];
      this.selectedRows = [];
      this.pageNumber = 1;
      this.SearchDto.skipCount = 0;
      this.SearchDto.maxResultCount = size;
      this.getData();
    },
    // 排序更新data
    updateSortData(newV) {
      this.selectedRowKeys = [];
      this.selectedRows = [];
      let { columnKey, order } = newV;
      this.SearchDto.sorting = order ? `${columnKey} ${order}` : "";
      this.getData();
    },
    updateSelectChange(newV) {
      let { selectedRowKeys, selectedRows } = newV;
      this.onSelectChange(selectedRowKeys, selectedRows);
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRows = selectedRows;
      // console.log(this.selectedRows);
    },
    updatePageChange(newV) {
      this.selectedRowKeys = [];
      this.selectedRows = [];
      let { page, pageSize, skipCount } = newV;
      this.onChange(page, skipCount);
    },
    onChange(page, pageSize) {
      this.selectedRowKeys = [];
      this.selectedRows = [];
      this.pageNumber = page;
      this.SearchDto.skipCount = (page - 1) * pageSize;
      this.SearchDto.maxResultCount = pageSize;
      this.getData();
    },
    clearAndGetData() {
      this.searchDrawerVisible = false;
      this.SearchDto.filterText = "";
      this.SearchDto.yearMonth = "";
      this.SearchDto.startPayTime = null;
      this.SearchDto.endPayTime = null;
      if (!this.disableStatus) {
        this.SearchDto.PayStatus = undefined;
      }

      this.SearchDto.CustomerId = undefined;
      this.SearchDto.PayCompanyId = undefined;
      this.SearchDto.skipCount = 0;
      this.pageNumber = 1;
      this.SearchDto.ProjectNames = "";
      this.SearchDto.ProjectIds = "";
        this.startToEndDate = [];
      this.getData();
    },
    selectProjectModal() {
      ModalHelper.create(
        SelectPageList,
        {
          selectType: "checkbox", // checkbox radio
          title: "选择项目", // 显示标题
          url: "/api/services/app/Project/GetSelectPaged", // 数据接口URL
          searchPlaceholder: "项目名称/项目代码",
          columnsData: [
            {
              title: "项目名称",
              dataIndex: "name",
              key: "name",
            },
            {
              title: "项目代码",
              dataIndex: "description",
              key: "description",
            },
          ],
        },
        {
          isChange: true,
          width: "860px",
          heigth: "650px",
        }
      ).subscribe((res) => {
        const { success, data } = res;
        if (success) {
          let projectIds = "";
          let projectNames = "";
          for (let i = 0; i < data.length; i += 1) {
            const item = data[i];
            if (i === data.length - 1) {
              projectIds += item.id;
              projectNames += item.name;
              continue;
            }
            projectIds += item.id + ",";
            projectNames += item.name + ",";
          }

          this.SearchDto.ProjectIds = projectIds;
          this.SearchDto.ProjectNames = projectNames;
          this.getData();
        }
      });
    },
    initCurMonth() {
      let d = new Date();
      let y = d.getFullYear();
      let m = d.getMonth() + 1;
      let _m = m < 10 ? `0${m}` : m;
      this.SearchDto.yearMonth = `${y}-${_m}`;
    },
  },
  created() {
    if (this.defaultStatus) {
      this.SearchDto.PayStatus = this.defaultStatus;
      this.disableStatus = true; //如果传了状态值，直接禁用状态选择
      console.log(this.SearchDto);
    }
    this.commonService = new CommonServiceProxy(this.$apiUrl, this.$api);
    // this.initCurMonth();
    this.initColumns();
    this.getAllPayCompany();
    this.getAllCutomer();
  },
  mounted() {
    console.log(this.SearchDto);
    this.getData();
  },
};
</script>
<style lang="less" scoped>
// .btn--container {
//   margin-top: 20px;
// }

// .btn--footer {
//   margin-bottom: 20px;
// }
</style>
