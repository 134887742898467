<template>
  <a-spin :spinning="spinning">
    <div class="modal-header">
      <div class="modal-title">
        <a-icon type="medicine-box" />
        <span>查看{{ realName }}的信息</span>
      </div>
    </div>
    <!-- tab切换 -->
    <a-tabs defaultActiveKey="1" @change="tabChange">
      <!-- 基本信息 -->
      <a-tab-pane key="1" force-render>
        <span slot="tab">
          <a-icon type="medicine-box" />
          {{ l("基本信息") }}
        </span>
        <personnel-basic-list :personnelId="PersonnelId"></personnel-basic-list>
      </a-tab-pane>
      <!--银行卡列表-->
      <a-tab-pane key="2" v-if="isGranted('Pages.LGModul.PersonnelRoster.BankInfoByPersonnelId')">
        <span slot="tab">
          <a-icon type="table" />
          {{ l("银行卡列表") }}
        </span>
        <personnel-bankinfo-list
          :personnelId="PersonnelId"
        ></personnel-bankinfo-list>
      </a-tab-pane>
      <!-- 签署列表 -->
      <a-tab-pane key="3" v-if="isGranted('Pages.LGModul.PersonnelRoster.ProjectAgreementByPersonnelId')">
        <span slot="tab">
          <a-icon type="safety-certificate" />
          {{ l("签署列表") }}
        </span>
        <personnel-agreement-list
          :personnelId="PersonnelId"
        ></personnel-agreement-list>
      </a-tab-pane>
      <!-- 执行记录 -->
      <a-tab-pane key="4" v-if="isGranted('Pages.LGModul.PersonnelRoster.ExecuteRecordsByPersonnelId')">
        <span slot="tab">
          <a-icon type="calculator" />
          {{ l("执行记录") }}
        </span>
        <personnel-taskexecute-list
          :personnelId="PersonnelId"
        ></personnel-taskexecute-list>
      </a-tab-pane>
      <!-- 考勤打卡 -->
      <a-tab-pane key="5" v-if="isGranted('Pages.LGModul.PersonnelRoster.SignInsByPersonnelId')">
        <span slot="tab">
          <a-icon type="clock-circle" />
          {{ l("考勤打卡 ") }}
        </span>
        <personnel-tasksign-list
          :personnelId="PersonnelId"
        ></personnel-tasksign-list>
      </a-tab-pane>
      <!-- 结算明细 -->
      <a-tab-pane key="6" v-if="isGranted('Pages.LGModul.PersonnelRoster.SettlementByPersonnelIdPaged')">
        <span slot="tab">
          <a-icon type="solution" />
          {{ l("结算明细") }}
        </span>
        <personnel-settlement-list
          :personnelId="PersonnelId"
        ></personnel-settlement-list>
      </a-tab-pane>
      <!-- 发放明细 -->
      <a-tab-pane key="7" v-if="isGranted('Pages.LGModul.PersonnelRoster.SalaryDetailByPersonnelId')">
        <span slot="tab">
          <a-icon type="transaction" />
          {{ l("发放明细") }}
        </span>
        <personnel-salarypay-list
          :personnelId="PersonnelId"
        ></personnel-salarypay-list>
      </a-tab-pane>
    </a-tabs>
  </a-spin>
</template>

<script>
import { ModalComponentBase } from "@/shared/component-base";
import PersonnelTasksignList from "./components/personnel-tasksign-list.vue";
import PersonnelBankinfoList from "./components/personnel-bankinfo-list.vue";
import PersonnelAgreementList from "./components/personnel-agreement-list.vue";
import PersonnelSettlementList from "./components/personnel-settlement-list.vue";
import PersonnelSalarypayList from "./components/personnel-salarypay-list.vue";
import PersonnelBasicList from "./components/personnel-basic-list.vue";
import PersonnelTaskexecuteList from "./components/personnel-taskexecute-list.vue";
export default {
  name: "personnelmanage-list",
  mixins: [ModalComponentBase],
  data() {
    return {
      spinning: false,

      activeKey: -1,
      baseInvoiceUrl: {
        getPaged: "Customer/GetCustomerBaseInvoicePaged",
        save: "Customer/SaveCustomerBaseInvoice",
        getItem: "Customer/GetCustomerBaseInvoiceById",
        delete: "Customer/DeleteCustomerBaseInvoice",
      },
      PersonnelId: "",
      realName: "",
    };
  },
  components: {
    PersonnelTasksignList,
    PersonnelBankinfoList,
    PersonnelAgreementList,
    PersonnelSettlementList,
    PersonnelSalarypayList,
    PersonnelBasicList,
    PersonnelTaskexecuteList,
  },
  created() {
    // console.log(this);
    this.fullData(); // 模态框必须,填充数据到data字段
  },
  mounted() {
    // console.log(this);
    this.PersonnelId = this.id;
    this.realName = this.realName;
  },
  methods: {
    tabChange(activeKey) {
      // this.activeKey = activeKey;
      // switch (activeKey) {
      //   case "1":
      //     // do sth...
      //     break;
      //   case "2":
      //     this.$refs.seal.getData(
      //       [{ key: "customerId", value: this.customer.id }],
      //       "/api/services/app/Customer/GetCustomerSealPaged"
      //     );
      //     break;
      //   case "3":
      //     this.$refs.account.getData(
      //       [{ key: "customerId", value: this.customer.id }],
      //       "/api/services/app/Customer/GetCustomerBankAccountPaged"
      //     );
      //     break;
      //   case "4":
      //     this.$refs.baseinvoice.getData(this.baseInvoiceUrl.getPaged);
      //     break;
      //   case "5":
      //     this.$refs.realname.getData(
      //       [{ key: "customerId", value: this.customer.id }],
      //       "/api/services/app/CustomerRealName/GetPaged"
      //     );
      //     break;
      //   case "6":
      //     this.$refs.provide.getData();
      //     break;
      //   case "7":
      //     this.$refs.authedUserList._getAuthedUserList();
      //     break;
      // }
    },
  },
};
</script>

<style scoped lang="less">
.modal-header {
  margin-bottom: 0;
  .anticon {
    margin-right: 10px;
  }
}
.pagination {
  margin: 10px auto;
  text-align: right;
}
</style>
