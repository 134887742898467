<template>
  <a-spin :spinning="spinning">
    <ele-table
      ref="rt"
      :columns="columns"
      :table-data="tableData"
      :total-items="totalItems"
      :is-full="false"
      :actionsType="actionsType"
      :current-page="pageNumber"
      :hide-row-selection="false"
      @emitRefreshData="clearFilterAndRefresh"
      @emitSelectChange="updateSelectChange"
      @emitOnPageChange="updatePageChange"
      @emitShowSizeChange="updateShowSizeChange"
    >
      <a slot="totalCount" slot-scope="text">{{ text }}</a>
      <a-row :gutter="8">
        <a-col class="gutter-row" :span="5">
          <a-input
            v-model="SearchDto.filterText"
            :placeholder="l('银行卡号/银行名称')"
          />
        </a-col>
        <a-col class="gutter-row" :span="4">
          <a-button type="primary" @click="search">
            {{ l("Search") }}
          </a-button>
          <a-button @click="refreshGoFirstPage"> {{ l("Reset") }}</a-button>
        </a-col>
      </a-row>
    </ele-table>
  </a-spin>
</template>

<script>
import { AppComponentBase } from "@/shared/component-base";
import EleTable from "@/components/ele-table";
import moment from "moment";
import { CommonServiceProxy } from "@/shared/common-service";

export default {
  mixins: [AppComponentBase],
  name: "personnel-bankinfo-list",
  props: ["personnelId"],
  components: { EleTable },

  data() {
    return {
      spinning: false,
      SearchDto: {
        SkipCount: 0,
        MaxResultCount: 50,
        Sorting: "",
        filterText: "",
      },
      totalItems: 0,
      pageNumber: 1,
      totalPages: 1,
      pagerange: [1, 1],
      pageSizeOptions: ["10", "20", "30", "40"],
      tableData: [],
      columns: [],
      commonService: null,
      actionsType: {},
      selectedRowKeys: [],
      selectedRows: [],
    };
  },
  created() {
    this.commonService = new CommonServiceProxy(this.$apiUrl, this.$api);
    this.initColumns();
    if (this.personnelId) {
      this.getData();
    }
  },
  methods: {
    initColumns() {
      this.columns = [
        {
          title: "银行卡号",
          dataIndex: "bankCardNo",
          align: "center",
          scopedSlots: { customRender: "bankCardNo" },
        },
        {
          title: "银行名称",
          dataIndex: "bankName",
          align: "center",
        },

        // {
        //   title: "所属分行",
        //   dataIndex: "bankBranchName",
        //   align: "center",
        // },
        {
          title: "开户地",
          dataIndex: "bankArea",
          align: "center",
        },
        {
          title: "是否默认",
          dataIndex: "isDefault",
          align: "center",
          scopedSlots: { customRender: "isDefault" },
          type: "tag",
        },
      ];
    },
    search() {
      this.pageNumber = 1;
      this.SearchDto.SkipCount = 0;
      this.getData();
    },
    getData() {
      this.getPaged();
    },
    async getPaged() {
      this.spinning = true;
      this.$nextTick(() => {
        this.$refs.rt.clearData();
      });
      try {
        let res = await this.commonService.post({
          url: "/api/services/app/Personnel/GetBankInfoByPersonnelIdPaged",
          params: {
            personnelId: this.personnelId,
            filterText: this.SearchDto.filterText,
            sorting: this.SearchDto.Sorting,
            maxResultCount: this.SearchDto.MaxResultCount,
            skipCount: this.SearchDto.SkipCount,
          },
        });
        // console.log(res);
        this.tableData = res.items.map((item) => {
          return {
            ...item,
            isDefault: item.isDefault ? "是" : "否",
            isDefaultColor: item.isDefault ? "geekblue" : "",
          };
        });
        this.totalItems = res.totalCount;
        this.totalPages = Math.ceil(2 / this.SearchDto.MaxResultCount);
        this.pagerange = [
          (this.pageNumber - 1) * this.SearchDto.MaxResultCount + 1,
          this.pageNumber * this.SearchDto.MaxResultCount,
        ];
        this.spinning = false;
      } catch (error) {
        this.spinning = false;
      }
    },
    // 排序更新data
    updateSortData(newV) {
      let { columnKey, order } = newV;
      this.SearchDto.Sorting = order ? `${columnKey} ${order}` : "";
      this.getData();
    },
    // 分页
    updatePageChange(newV) {
      let { page, pageSize } = newV;
      this.onChange(page, pageSize);
    },
    updateShowSizeChange(newV) {
      let { current, size } = newV;
      this.showSizeChange(current, size);
    },
    // table选择事件
    updateSelectChange(newV) {
      let { selectedRowKeys, selectedRows } = newV;
      this.onSelectChange(selectedRowKeys, selectedRows);
    },
    onChange(page, pageSize) {
      this.pageNumber = page;
      this.SearchDto.SkipCount = (page - 1) * this.SearchDto.MaxResultCount;
      this.getData();
    },
    showSizeChange(current, size) {
      this.pageNumber = 1;
      this.SearchDto.MaxResultCount = size;
      this.getData();
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRows = selectedRows;
    },
    // 清除条件并刷新
    clearFilterAndRefresh() {
      this.refreshGoFirstPage();
    },
    refreshGoFirstPage() {
      this.SearchDto.filterText = "";
      this.pageNumber = 1;
      this.SearchDto.SkipCount = 0;
      this.getData();
    },
    // 分页事件
    showTotalFun() {
      return this.l(
        "GridFooterDisplayText",
        this.pageNumber,
        this.totalPages,
        this.totalItems,
        this.pagerange[0],
        this.pagerange[1]
      );
    },
  },
};
</script>

<style scoped lang="less">
</style>
