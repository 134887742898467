<template>
  <a-spin :spinning="spinning">
    <ele-table
      ref="rt"
      :columns="columns"
      :table-data="tableData"
      :total-items="totalItems"
      :is-full="false"
      :actionsType="actionsType"
      :current-page="pageNumber"
      :hide-row-selection="false"
      @emitRefreshData="clearFilterAndRefresh"
      @emitSelectChange="updateSelectChange"
      @emitOnPageChange="updatePageChange"
      @emitShowSizeChange="updateShowSizeChange"
      @emitLinkClick="linkClick"
    >
      <a slot="totalCount" slot-scope="text">{{ text }}</a>
      <a-row :gutter="8">
        <a-col class="gutter-row" :span="5">
          <a-input
            v-model="SearchDto.name"
            :placeholder="l('任务名称/任务编码')"
          />
        </a-col>
        <a-col class="gutter-row" :span="5">
          <a-range-picker
            :placeholder="['开始日期', '结束日期']"
            v-model="startToEndDate"
            @change="onPickerChange"
          />
        </a-col>
        <a-col class="gutter-row" :span="6">
          <a-button type="primary" @click="search">
            {{ l("Search") }}
          </a-button>
          <a-button @click="refreshGoFirstPage"> {{ l("Reset") }}</a-button>
          <!-- <a-button @click="exportToExcel()">
            <a-icon type="file-excel" />
            <span>{{ l("Export") }}</span>
          </a-button> -->
        </a-col>
      </a-row>
    </ele-table>
  </a-spin>
</template>

<script>
import { AppComponentBase } from "@/shared/component-base";
import { ModalHelper } from "@/shared/helpers";
import EleTable from "@/components/ele-table";
import moment from "moment";
import { CommonServiceProxy } from "@/shared/common-service";
import { AppConsts } from "@/abpPro/AppConsts";
import Trajectory from "../../../task/task-duty-pannel/trajectory.vue";
import { fileDownloadService } from "@/shared/utils";
import "moment/locale/zh-cn";

export default {
  mixins: [AppComponentBase],
  name: "personnel-tasksign-list",
  props: ["personnelId"],
  components: { EleTable },

  data() {
    return {
      spinning: false,
      SearchDto: {
        SkipCount: 0,
        MaxResultCount: 50,
        Sorting: "",
        filterText: "",
        name: "",
        startDate: "",
        endDate: "",
      },
      name: "",
      selectedRowKeys: [],
      totalItems: 0,
      pageNumber: 1,
      totalPages: 1,
      pagerange: [1, 1],
      pageSizeOptions: ["10", "20", "30", "40"],
      tableData: [],
      columns: [],
      child: "",
      commonService: null,
      selectProjectList: "",
      actionsType: {},
      trajectory: {
        destroyOnClose: true,
        maskClosable: false,
        confirmLoading: false,
        visible: false,
        title: "查看打卡轨迹",
        cancelText: "取消",
        okText: "确认",
        form: {},
        bodyStyle: {
          width: "700px",
          height: "600px",
        },
        confirm: () => {},
        cancel: () => {},
      },
      trajectoryList: [],
      startToEndDate: [],
    };
  },
  created() {
    console.log(this);
    this.commonService = new CommonServiceProxy(this.$apiUrl, this.$api);
    this.initColumns();
    this.getData();
  },
  mounted() {},
  methods: {
    initColumns() {
      var vm = this;
      this.columns = [
        {
          title: this.l("任务编码"),
          dataIndex: "taskCode",
          sorter: false,
          align: "center",
          scopedSlots: { customRender: "name" },
        },
        {
          title: this.l("任务名称"),
          dataIndex: "taskName",
          sorter: false,
          align: "center",
          scopedSlots: { customRender: "name" },
        },
        {
          title: this.l("姓名"),
          dataIndex: "name",
          sorter: false,
          align: "center",
          scopedSlots: { customRender: "name" },
        },
        {
          title: this.l("打卡日期"),
          dataIndex: "signInTime1",
          sorter: false,
          align: "center",
          scopedSlots: { customRender: "signInTime1" },
        },
        {
          title: this.l("打卡时间"),
          dataIndex: "signInTime2",
          sorter: false,
          align: "center",
          scopedSlots: { customRender: "signInTime2" },
        },
        {
          title: this.l("打卡地点"),
          dataIndex: "address",
          sorter: false,
          align: "center",
          scopedSlots: { customRender: "address" },
        },
        {
          title: this.l("打卡照片"),
          dataIndex: "photoUrl",
          sorter: false,
          type: "link",
          align: "center",
          customRender: function (text, record, index) {
            var child = vm.$createElement("a", {
              domProps: {
                innerHTML: "查看",
              },
              on: {
                click: function () {
                  vm.noSignClick(record);
                },
              },
            });
            var obj = {
              //   children: <a href="https://www.baidu.com/">{text + " (查看)"}</a>,
              children: child,
              attrs: {},
              on: {
                click: () => {
                  this.$message.info(text);
                },
              },
            };
            return obj;
          },
          scopedSlots: { customRender: "photoUrl" },
        },
        {
          title: this.l("打卡轨迹"),
          dataIndex: "",
          sorter: false,
          type: "link",
          align: "center",
          customRender: function (text, record, index) {
            var child = vm.$createElement("a", {
              domProps: {
                innerHTML: "查看",
              },
              on: {
                click: async function () {
                  let list = await vm._getSignTracks(record.id);
                  console.log("list", list);
                  if (list && list.length) {
                    vm._trajectory(list);
                  } else {
                    vm.$message.info("暂无打卡记录");
                  }
                },
              },
            });
            var obj = {
              children: child,
              attrs: {},
            };
            return obj;
          },
          scopedSlots: { customRender: "" },
        },
        {
          title: this.l("当前任务累计打卡次数"),
          dataIndex: "count",
          sorter: false,
          align: "center",
          scopedSlots: { customRender: "count" },
        },
        // {
        //   title: this.l("Action"),
        //   dataIndex: "actions",
        //   fixed: "right",
        //   scopedSlots: { customRender: "actions" },
        // },
      ];
    },
    search() {
      this.pageNumber = 1;
      this.SearchDto.SkipCount = 0;
      this.getData();
    },
    getData() {
      this.getPaged();
    },
    async getPaged() {
      this.spinning = true;
      this.$nextTick(() => {
        this.$refs.rt.clearData();
      });
      try {
        let res = await this.commonService.post({
          url: "/api/services/app/Personnel/GetSignInsByPersonnelIdPaged",
          params: {
            personnelId: this.personnelId,
            name: this.SearchDto.name,
            startDate: this.SearchDto.startDate,
            endDate: this.SearchDto.endDate,
            filterText: this.SearchDto.filterText,
            sorting: this.SearchDto.Sorting,
            maxResultCount: this.SearchDto.MaxResultCount,
            skipCount: this.SearchDto.SkipCount,
          },
        });
        this.tableData = res.items.map((item) => {
          return {
            ...item,
            signInTime1: item.signInTime
              ? moment(item.signInTime).format("YYYY-MM-DD")
              : "-",
            signInTime2: item.signInTime
              ? moment(item.signInTime).format("HH:mm:ss")
              : "-",
          };
        });
        this.totalItems = res.totalCount;
        this.totalPages = Math.ceil(2 / this.SearchDto.MaxResultCount);
        this.pagerange = [
          (this.pageNumber - 1) * this.SearchDto.MaxResultCount + 1,
          this.pageNumber * this.SearchDto.MaxResultCount,
        ];
        this.spinning = false;
      } catch (error) {
        this.spinning = false;
      }
    },
    /**
     * 导出为excel
     */
    async exportToExcel() {
      this.spinning = true;
      this.$nextTick(() => {
        this.$refs.rt.clearData();
      });
      try {
        let res = await this.commonService.post({
          url: "/api/services/app/PersonnelTask/GetTaskSignInsPagedByTaskIdDataExcelFile",
          params: {
            taskId: this.taskId,
            name: this.SearchDto.name,
            startDate: this.SearchDto.startDate,
            endDate: this.SearchDto.endDate,
            filterText: this.SearchDto.filterText,
            sorting: this.SearchDto.Sorting,
            maxResultCount: this.SearchDto.MaxResultCount,
            skipCount: this.SearchDto.SkipCount,
          },
        });
        console.log(res);
        fileDownloadService.downloadTempFile(res);
        this.spinning = false;
      } catch (error) {
        console.log(error);
        this.spinning = false;
      }
    },
    clearFilterAndRefresh() {
      this.SearchDto.SkipCount = 0;
      this.getData();
    },
    _trajectory(trajectoryList) {
      ModalHelper.create(
        Trajectory,
        {
          trajectoryList: trajectoryList,
        },
        {
          isChange: true,
          width: "1200px",
        }
      ).subscribe((res) => {
        if (res) {
          this.refreshGoFirstPage();
        }
      });
    },
    async _getSignTracks(taskSignInId) {
      this.spinning = true;
      try {
        let res = await this.commonService.get({
          url: "/api/services/app/PersonnelTask/GetSignTracks",
          params: { taskSignInId: taskSignInId },
        });
        console.log(res);
        if (res && res.length) {
          let arr = [];
          res.map((item) => {
            if (item.latitude && item.longitude) {
              console.log(item);
              arr.push({
                lat: item.latitude,
                lng: item.longitude,
                signInTime: moment(item.signInTime).format(
                  "YYYY-MM-DD HH:mm:ss"
                ),
                address: item.address,
              });
            }
          });
          // console.log(arr)
          this.spinning = false;
          return arr;
        } else {
          return null;
          this.spinning = false;
        }
      } catch (error) {
        console.log(error);
        this.spinning = false;
      }
    },
    async linkClick(newV) {
      const { item, index } = newV;
      if (index === 6) {
        this.noSignClick(item);
      } else if (index === 7) {
        let list = await this._getSignTracks(item.id);
        if (list && list.length) {
          this._trajectory(list);
        } else {
          this.$message.info("暂无打卡记录");
        }
      }
    },
    noSignClick(item) {
      //   this.message.success("测试");
      // this.lookSignPerson(item, 0);
      console.log(item);
      const { photoUrl } = item;
      if (photoUrl) {
        window.open(`${AppConsts.uploadBaseUrl}${photoUrl}`);
      } else {
        this.$message.info("暂无照片");
      }
    },
    // 排序更新data
    updateSortData(newV) {
      let { columnKey, order } = newV;
      this.getData();
    },
    // 分页
    updatePageChange(newV) {
      let { page, pageSize } = newV;
      this.onChange(page, pageSize);
    },
    updateShowSizeChange(newV) {
      let { current, size } = newV;
      this.showSizeChange(current, size);
    },
    // table选择事件
    updateSelectChange(newV) {
      let { selectedRowKeys, selectedRows } = newV;
      this.onSelectChange(selectedRowKeys, selectedRows);
    },
    onChange(page, pageSize) {
      this.pageNumber = page;
      this.SearchDto.SkipCount = (page - 1) * this.SearchDto.MaxResultCount;
      this.getData();
    },
    showSizeChange(current, size) {
      this.pageNumber = 1;
      this.SearchDto.MaxResultCount = size;
      this.getData();
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRows = selectedRows;
    },
    // 清除条件并刷新
    clearFilterAndRefresh() {
      this.refreshGoFirstPage();
    },
    refreshGoFirstPage() {
      this.SearchDto.name = "";
      this.SearchDto.filterText = "";
      this.pageNumber = 1;
      this.SearchDto.SkipCount = 0;
      this.startToEndDate = [];
      this.SearchDto.startDate = "";
      this.SearchDto.endDate = "";
      this.getData();
    },
    // 分页事件
    showTotalFun() {
      return this.l(
        "GridFooterDisplayText",
        this.pageNumber,
        this.totalPages,
        this.totalItems,
        this.pagerange[0],
        this.pagerange[1]
      );
    },
    onPickerChange(date, dateString) {
      console.log(date, dateString);
      this.SearchDto.startDate = dateString[0];
      this.SearchDto.endDate = dateString[1];
    },
  },
};
</script>

<style scoped lang="less">
.trajectory {
  padding: 20px;
  .w {
    #myMap {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
